export class User {
    name: string = '';
    email: string = '';
    accessToken: string = '';
    accessTokenExpirationDate?: Date;
    idToken: string = '';
    refreshToken: string = '';
    agosIdentityToken: string = '';

    constructor(init?:Partial<User>) {
        Object.assign(this, init);
    }

    public getAccessToken() {
        if (!this.accessToken || !this.accessTokenExpirationDate || new Date() > new Date(this.accessTokenExpirationDate)) {
                return null;
        }

        return this.accessToken;
    }

    public getIdToken() {
        if (!this.accessToken || !this.accessTokenExpirationDate || new Date() > new Date(this.accessTokenExpirationDate)) {
                return null;
        }
        
        return this.idToken;
    }    
}