import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './.core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./login/login.component').then(m => m.LoginComponent),
    data: { reuseRoute: false }
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
    data: { reuseRoute: false }
  },
  {
    path: 'my-savings',
    canActivate: [AuthGuard],
    loadComponent: () => import('./my-savings/my-savings.component').then(m => m.MySavingsComponent)
  },
  {
    path: 'my-deposit-plan',
    canActivate: [AuthGuard],
    loadComponent: () => import('./my-deposit-plan/my-deposit-plan.component').then(m => m.MyDepositPlanComponent)
  },
  {
    path: 'contact-us',
    canActivate: [AuthGuard],
    loadComponent: () => import('./contact-us/contact-us.component').then(m => m.ContactUsComponent)
  },
  {
    path: 'my-account/overview',
    canActivate: [AuthGuard],
    loadComponent: () => import('./account-overview/account-overview.component').then(m => m.AccountOverviewComponent)
  },
  {
    path: 'my-account/purchases/prepay-open',
    canActivate: [AuthGuard],
    loadComponent: () => import('./prepay-open/prepay-open.component').then(m => m.PrepayOpenComponent)
  },
  {
    path: 'my-account/purchases/prepay-open-details',
    canActivate: [AuthGuard],
    loadComponent: () => import('./prepay-open-details/prepay-open-details.component').then(m => m.PrepayOpenDetailsComponent)
  },
  {
    path: 'my-account/purchases/account-details',
    canActivate: [AuthGuard],
    loadComponent: () => import('./account-details/account-details.component').then(m => m.AccountDetailsComponent)
  },
  {
    path: 'my-account/purchases/purchase-details',
    canActivate: [AuthGuard],
    loadComponent: () => import('./purchase-details/purchase-details.component').then(m => m.PurchaseDetailsComponent)
  },
  {
    path: 'my-account/purchases/statements',
    canActivate: [AuthGuard],
    loadComponent: () => import('./statements/statements.component').then(m => m.StatementsComponent)
  },
  {
    path: 'my-account/purchases/purchase-summaries',
    canActivate: [AuthGuard],
    loadComponent: () => import('./purchase-summaries/purchase-summaries.component').then(m => m.PurchaseSummariesComponent)
  },
  {
    path: 'my-account/payments/make-a-payment',
    canActivate: [AuthGuard],
    loadComponent: () => import('./make-a-payment/make-a-payment.component').then(m => m.MakeAPaymentComponent)
  },
  {
    path: 'my-account/payments/payment-options',
    canActivate: [AuthGuard],
    loadComponent: () => import('./payment-options/payment-options.component').then(m => m.PaymentOptionsComponent)
  },
  {
    path: 'my-account/payments/payment-history',
    canActivate: [AuthGuard],
    loadComponent: () => import('./payment-history/payment-history.component').then(m => m.PaymentHistoryComponent)
  },
  {
    path: 'my-account/payments/payment-requests',
    canActivate: [AuthGuard],
    loadComponent: () => import('./payment-requests/payment-requests.component').then(m => m.PaymentRequestsComponent)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadComponent: () => import('./admin/admin.component').then(m => m.AdminComponent)
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }  
];

@NgModule({
  // Note: The above modules are all configured for lazy loading to speed up app start time.
  // However we use the PreloadAllModules strategy below to load the modules in the background after
  // the application has started. In the future if the number of modules increases to a large number
  // and many of them are not commonly used we can create our own custom strategy to only
  // load certain modules to save bandwidth and only preload the most used ones.
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
