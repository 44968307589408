<body style="overflow: hidden !important;">
  <div id="app-container" style="display: flex; flex-direction: column; height: 100%;">

    <div id="header">
      <header *ngIf="headerVisible" class="flex flex-row align-items-center">
        <button class="menu-button" (click)="onToggleSideNavClick()"></button>
        <div class="logo flex-auto">The McGregor Company</div>
        <button type="button" pButton pRipple class="header-button p-button-rounded p-button-text p-button-lg p-button-plain" 
            icon="pi pi-fw pi-user" (click)="userMenu.toggle($event)"></button>
        <p-menu #userMenu [popup]="true" [model]="userMenuItems" class="mr-2"></p-menu>
      </header>
    </div>

    <div id="content" style="flex:1; overflow-y: auto; max-height: calc(100vh - var(--header-height));">
      <div class="sidenav" [ngClass] = "{'opened': sideNavVisible, 'closed': !sideNavVisible}">
        <div class="loading group" [ngClass]="{'hidden': (!navLoading)}">
          <a class="level-1 control">Loading Navigation</a>
        </div>
        <div class="my-account group" [ngClass]="{'expanded': myAccountNavToggle, 'collapsed': !myAccountNavToggle, 'hidden': (navLoading)}">
          <a class="level-1 control" (click)="controlClick('my-account')" [ngClass]="{'active-route': checkActiveRoute('my-account/')}">My Account</a>
          <a class="level-1 item" routerLink="/my-account/overview" routerLinkActive="active">Account Overview</a>
          <div class="purchases group"  [ngClass]="{'expanded': purchasesNavToggle, 'collapsed': !purchasesNavToggle}">
            <a class="level-2 control" (click)="controlClick('purchases')" [ngClass]="{'active-route': checkActiveRoute('purchases/')}">Purchases</a>
            <a class="level-2 item" routerLink="/my-account/purchases/prepay-open" routerLinkActive="active">Prepay Open</a>
            <a class="level-2 item" routerLink="/my-account/purchases/prepay-open-details" routerLinkActive="active">Prepay Open Details</a>
            <a class="level-2 item" routerLink="/my-account/purchases/account-details" routerLinkActive="active">Account Details</a>
            <a class="level-2 item" routerLink="/my-account/purchases/purchase-details" routerLinkActive="active">Purchase Details</a>
            <a class="level-2 item" routerLink="/my-account/purchases/statements" routerLinkActive="active">Statements</a>
            <a class="level-2 item" routerLink="/my-account/purchases/purchase-summaries" routerLinkActive="active">Purchase Summaries</a>
          </div>
          <div class="payments group"  [ngClass] = "{'expanded': paymentsNavToggle, 'collapsed': !paymentsNavToggle}">
            <a class="level-2 control" (click)="controlClick('payments')" [ngClass] = "{'active-route': checkActiveRoute('payments/')}">Payments</a>
            <a class="level-2 item" routerLink="/my-account/payments/make-a-payment" routerLinkActive="active">Make a Payment</a>
            <a class="level-2 item" routerLink="/my-account/payments/payment-options" routerLinkActive="active">Payment Options</a>
            <a class="level-2 item" routerLink="/my-account/payments/payment-history" routerLinkActive="active">Payment History</a>
            <a class="level-2 item" routerLink="/my-account/payments/payment-requests" routerLinkActive="active">Payment Requests</a>
          </div>
        </div>
        <a routerLink="/my-deposit-plan" routerLinkActive="active" [ngClass]="{'hidden': (!authService.userPermissions?.deposit_plan || navLoading)}">My Deposit Plan</a>
        <a routerLink="/contact-us" routerLinkActive="active" [ngClass]="{'hidden': (navLoading)}">Contact Us</a>
        <div class="color-fill">
          <div class="legal-notice">
            <a href="https://www.mcgregor.com/about/privacy" target="_blank">Privacy Policy</a><a href="https://www.mcgregor.com/about/terms-of-use" target="_blank">Terms of Use</a>
          </div>
        </div>
      </div>
      <div class="content-panel" [ngClass] = "{
                                                'sidenav-opened': sideNavVisible, 
                                                'sidenav-closed': !sideNavVisible}">
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>
</body>