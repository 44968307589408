import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

import { HttpClient } from '@angular/common/http';
import { AuthService } from './.core/services/auth.service';
import { UserPermissions } from './.types/interfaces/user-permissions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  subscriptions: Array<Subscription> = new Array<Subscription>();
  headerVisible: boolean = false;
  sideNavVisible: boolean = false;
  myAccountNavToggle: boolean = false;
  purchasesNavToggle: boolean = false;
  paymentsNavToggle: boolean = false;
  userIsAdmin: boolean = false;
  navLoading: boolean = true;
  userMyDepositPlanPermission: boolean = false;
  userMenuItems: MenuItem[] = [
    { label: 'Admin', routerLink: ['/admin'], visible: false },
    { label: 'Change Password', command: () => this.authService.requestChangePassword() },
    { label: 'Logout', command: () => this.logout() },
  ];

  constructor(
    public authService: AuthService,
    private router: Router,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.userAuthenticated$.subscribe(() => this.onUserAuthenticated()),
      this.authService.userAutoAuthenticated$.subscribe(() => this.onUserAuthenticated(true)),
      this.authService.userLoggedOut$.subscribe(() => this.onUserLoggedOut()),
    );
    // Before the app loads we check localStorage for saved user data and see if the access token 
    // is still valid. If it is, the authService will communicate to this component via the 
    // userAutoLogin$ Observable above. If not, the user is routed to the default login path.
    this.authService.autoLogin();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => { 
      let url = this.router.url;

      let index = url.lastIndexOf('/');
      let viewName = url.substring(index + 1);

      this.closeSideNavClick(viewName);
    });  
  }

  checkActiveRoute(routeToCheck: string = ""): boolean {
    return this.router.url.includes(routeToCheck);
  }

  onToggleSideNavClick(): void {
    this.sideNavVisible = !this.sideNavVisible;
  }

  closeSideNavClick(viewName: string = ""): void {
    if (viewName === "account-overview"){
      this.myAccountNavToggle = true;
    } 
    else if (viewName === "prepay-open" 
      || viewName === "prepay-open-details" 
      || viewName === "account-details"
      || viewName === "purchase-details" 
      || viewName === "statements"
      || viewName === "purchase-summaries") {
        this.myAccountNavToggle = true;
        this.purchasesNavToggle = true;
    } 
    else if (viewName === "make-a-payment"
      || viewName === "payment-options"
      || viewName === "payment-history"
      || viewName === "payment-requests") {
        this.myAccountNavToggle = true;
        this.paymentsNavToggle = true;
    } 

    if(window.innerWidth < 600){
      this.sideNavVisible = false;
    }
  }

  controlClick(groupName: string = ""): void {
    if (groupName === "my-account") {
      this.myAccountNavToggle = !this.myAccountNavToggle;
    }
    else if (groupName === "purchases") {
      this.purchasesNavToggle = !this.purchasesNavToggle;
    }
    else if (groupName === "payments") {
      this.paymentsNavToggle = !this.paymentsNavToggle;
    }
  }

  private onUserAuthenticated(autoAuthenticated: boolean = false): void {
    this.headerVisible = true;
    this.sideNavVisible = true;

    if(!autoAuthenticated){
      this.router.navigate(['/home']);
    }

    let currentUsername = this.authService.user.name;
    let me = this;
    this.loadUserPermissions().subscribe((response: any) => {
      if(response[1] === 200) {
        if(response[0]){
          me.authService.allUserPermissions = response[0];
          me.authService.allUserPermissions.forEach(function (record: UserPermissions) {
            if(record.name === currentUsername){
              me.authService.userPermissions = record;

              //Enable the admin nav option
              me.userMenuItems.forEach(function (item: any) {
                if(item.label === 'Admin' && me.authService.userPermissions?.admin) {
                  item.visible = true;
                }
              });
            }
          });

          me.navLoading = false;
        }
      } else {
        
        //Handle errors
        let error = `Error ${response[1]}: `;
        if(response[2]) { 
          error.concat(`${response[2]}`);
        }
        window.alert(error);
        console.log(error);  
      }
    });
  }

  private onUserLoggedOut(): void {
    this.headerVisible = false;
    this.sideNavVisible = false;
  }

  private logout(): void {
    this.headerVisible = false;
    this.sideNavVisible = false;
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadUserPermissions(): Observable<UserPermissions[]> {
    const results = this.http.get<UserPermissions[]>('/api/users');
    return results;
  }
}

